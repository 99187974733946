import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import { Navigation } from "swiper/modules";
import Reviews from "../assets/home/reviews.json";

export default function App() {
  const images = {};

  function importAll(r) {
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
  }
  importAll(
    require.context("../assets/home/reviews", false, /\.(png|webp|jpe?g|svg)$/)
  );

  function starCount(stars) {
    var indents = [];
    for (var i = 0; i < stars; i++) {
      indents.push(<i className="fa-solid fa-star text-default"></i>);
    }
    return indents;
  }

  const [expandedId, setExpandedId] = useState(null);

  const handleClick = (id) => {
    setExpandedId(id === expandedId ? null : id);
  };

  function openAtTheEnd() {
    setExpandedId(null);
  }

  return (
    <>
      <Swiper
        onSlideChange={openAtTheEnd}
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {Reviews.map((review) => (
          <SwiperSlide className="text-dark text-center">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-4 col-md-6 col-lg-5 col-xl-4 mb-3">
                <img
                  className="img-fluid w-100 rounded-circle"
                  src={images[review.image]}
                  alt="immagine profilo utente"
                />
              </div>
              <div className="col-12">
                <h6 className="text-dark">{review.name}</h6>
                <div className="text-dark star-wrapper">
                  {starCount(review.stars)}
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center align-items-center mt-3">
              <div className="col-12 text-center fs-5-psc">
                <p
                  onClick={() => handleClick(review.id)}
                  className={
                    expandedId === review.id ? "expanded" : "collapsed"
                  }
                >
                  {review.text}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
