import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import axios from "axios";
import LandingCareisgold from "./landing/oroDaInvestimento/OroDaInvestimento";
import "./index.css";


function App() {

  return (
    <div className="App">
        <Router>
            <Routes>
                <Route  path="/*" element={<Navigate to="/oro-da-investimento" replace/>} />
                <Route exact path="/oro-da-investimento" element={<LandingCareisgold />} />
            </Routes>   
        </Router>
    </div>
  );
}

export default App;
