import React, { useEffect, useRef, useState } from "react";
import ContactForm from "../../components/ContactForm";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from '../../components/Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import headerVideo from "../../assets/landing/oroDaInvestimento/lingotto_animato.mp4";
import imgPadre from "../../assets/landing/oroDaInvestimento/father-carrying-little-son-on-shoulders-on-a-hikin-2023-11-27-05-12-28-utc.jpg";
import imgFamily from "../../assets/landing/oroDaInvestimento/happy-family-standing-on-the-field-at-the-sunset-t-2023-11-27-04-51-59-utc.jpg";
import imgGrafico from "../../assets/landing/oroDaInvestimento/grafico_landing.png";
import logoCare from "../../assets/logo/logo1.webp";
import icon1 from "../../assets/landing/oroDaInvestimento//safe-box.png";
import icon2 from "../../assets/landing/oroDaInvestimento/earning.png";
import icon3 from "../../assets/landing/oroDaInvestimento/mobile-app.png";
import icon4 from "../../assets/landing/oroDaInvestimento/exchange.png";
import imgDevices from "../../assets/landing/oroDaInvestimento/PC-tab-cel_1.png";
import Reviews from "../../components/reviews";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Autoplay, Navigation } from 'swiper/modules';
import axios from "axios";
import { Link } from "react-router-dom";


function Careisgold() {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState(posts);
  const [uniqueCatPosts, setUniqueCatPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);


  const openModal = () => {
    document.body.style.overflow = 'hidden';
    setIsModalOpen(true);
  };

  const closeModal = () => {
    const errorModal = document.querySelector('#errorPage');
    const successModal = document.querySelector('#thankyouPage');

    if(errorModal){
      window.location.reload();
      return;
    }

    if(successModal){
      window.location.href = 'https://www.careisgold.it/'
      return;
    }

    document.body.style.overflow = 'auto'; 
    setIsModalOpen(false);
  };

  useEffect(() => {

    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        closeModal();
      }
    };
    
    if (true) {
      document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };

  }, []);


  const handleScroll = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        const res = await axios.post(`https://crm.careholding.it/ws/Call/?Cat=Blog&met=GetPostsRS&np=0`);
        setPosts(res.data);
        setFilteredPosts(res.data);
        
        const uniqueCatValues = [...new Set(res.data.map(post => post.IdCategory))];
        const postsByUniqueCat = {};

        uniqueCatValues.forEach(uniqueCatValue => {
            const filteredPosts = res.data.filter(
              post => post.IdCategory === uniqueCatValue && post?.Stato === 1 // Optional chaining for 'Stato'
            );
            postsByUniqueCat[uniqueCatValue] = filteredPosts;
          });

        setUniqueCatPosts(postsByUniqueCat);
  
  
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  
  }, []);


  function arrowFV (){
    const startContent = document.querySelector("#startContent");
    const topOfElement = startContent.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
        top: topOfElement,
        behavior: "smooth",
    });
}

  
  return (
    <>
        <div className="fullscreen-video-fv">
          <video playsInline autoPlay muted loop>
              <source src={headerVideo} type="video/mp4" />
              Your browser does not support the video tag.
          </video>
          <div className="overlay-content-odi">
              <h1 className="fw-bold text-light h1-fv outline-text montserrat">PROTEGGI I TUOI RISPARMI CON L'ORO FISICO DA INVESTIMENTO</h1>
              <p className="text-light fs-3 mt-md-5 outline-text p-odi">La sicurezza del domani parte da un investimento pensato oggi</p>
              <div className="text-center">
                  <FontAwesomeIcon icon="fa-solid fa-chevron-down" className="display-1 title-landing-odi arrow-icon-fv mt-md-5 mt-5" onClick={arrowFV} />
              </div>
          </div>
          <div className="overlay-logo-odi">
              <img src={logoCare} className="img-fluid" alt="logo careisgold s.p.a." />
          </div>
        </div>
        <div className="container-fluid bg-landing-care">
          <div className="container" id="startContent">
            <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-xl-8 col-lg-8 col-12 text-center py-md-5 py-4 px-md-4">
                    <p className="fw-semibold text-uppercase fs-2 mb-4 mt-4 title-landing-odi" data-aos="fade-zoom-in">UN ITALIANO SU CINQUE HA SCELTO L'ORO
                    PER PROTEGGERE I PROPRI RISPARMI</p>
                  </div>
            </div>
              <div className="row d-flex justify-content-center align-items-center text-center">
                  <div className="col-xl-7 col-lg-10 col-12 text-center mt-lg-0">
                      <img src={imgPadre} className="img-fluid" alt="famiglia" data-aos="fade-right"/>
                  </div>
                  <div className="col-xl-5 col-lg-10 col-12 text-center text-light mt-3 mt-xl-0">
                    <div data-aos="fade-left">
                    <p className="fs-4 fw-semibold text-start">OGGI CREARSI UNA RISERVA AUREA È UN'OPPORTUNITÀ ALLA PORTATA DI TUTTI.</p>
                    <p className="fs-5 text-start">Careisgold SpA offre piani di acquisto ricorrente per dare la possibilità a chiunque di accumulare Oro fisico in modo semplice, sicuro e conveniente.</p>
                    <p className="fs-5 text-start">Perché prendersi cura delle persone che ami è il vero investimento per il futuro.</p>
                    <button onClick={openModal} className="border-0 bg-landing-odi text-dark px-4 py-2 rounded-pill fw-semibold mt-4">Scopri come iniziare</button>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div className="container-fluid bg-landing-care">
          <div className="container">
              <div className="row d-flex justify-content-center align-items-center text-center py-md-5 py-4 px-4">
                  <div className="col-xl-4 col-12 text-xl-start text-center text-light">
                    <div data-aos="fade-right">
                      <p className="fs-4 fw-semibold">IL VALORE DELL'ORO NEL TEMPO</p>
                      <ul className="p-3">
                        <li className="fs-5 text-start">tangibile</li>
                        <li className="fs-5 text-start">senza ente emittente</li>
                        <li className="fs-5 text-start">a rischio zero</li>
                        <li className="fs-5 text-start">immune al fallimento</li>
                      </ul>
                      <p className="fs-5 text-start">L'oro fisico è da sempre il bene rifugio per eccellenza!</p>
                    </div>
                  </div>
                  <div className="col-xl-8 col-12 mt-md-0 mt-3 text-center">
                      <img src={imgGrafico} className="img-fluid" alt="grafico valore oro nel tempo" data-aos="fade-left"/>
                  </div>
              </div>          
            </div>
        </div>
        <div className="container-fluid bg-landing-care">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-8 col-12 text-center pb-md-5">
                <p className="fw-semibold text-uppercase title-landing-odi fs-4" data-aos="fade-zoom-in">L'ORO È UNA RISORSA PREZIOSA E LIMITATA. <br/>  non aspettare!</p>
                <button onClick={openModal} data-aos="fade-zoom-in" className="border-0 bg-landing-odi text-uppercase text-dark px-5 py-3 rounded-pill mt-md-4 mb-md-0 my-4 fw-semibold">Approfondisci</button>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light">
          <div className="container pt-5">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-12 text-center">
                <p className="text-dark fw-semibold m-0 pb-0 fs-3">Le recensioni dei nostri clienti</p>
              </div>
              <div className="col-12">
                <Reviews className="text-light"/>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-landing-care">
          {/* desktop */}
          <div className="d-none d-md-block container pb-5">
            <div className="row d-flex justify-content-center align-items-top">
              <div className="col-12 text-center my-5">
                <p className="fw-semibold fs-1 m-0 title-landing-odi" data-aos="fade-zoom-in">L'innovazione di Careisgold SpA</p>
                <p className="fw-semibold text-light fs-3" data-aos="fade-zoom-in">Banco metalli autorizzato da Banca d'Italia</p>
              </div>
              <div className="col-xl-6 col-10 mb-xl-0 mb-5 d-flex justify-content-center align-items-top mb-3">
                <div className="col-md-2 col-6 d-flex align-items-center">
                  <img src={icon1} className="img-fluid w-75" alt="icona" data-aos="fade-zoom-in"/>
                </div>
                <div className="col-md-9 col-6">
                  <p className="fw-bold fs-3 title-landing-odi" data-aos="fade-zoom-in">Acquisto e stoccaggio dell'Oro</p>
                  <p className="text-light" data-aos="fade-zoom-in">Il cliente ha facoltà di detenere l’oro nel luogo dove preferisce oppure può scegliere di conservarlo nei sicurissimi caveaux presso Battistolli in Italia beneficiando di una <b>copertura assicurativa al 100%</b>.</p>
                </div>
              </div>
              <div className="col-xl-6 col-10 mb-xl-0 mb-5 d-flex justify-content-center align-items-top mb-3">
                <div className="col-2 d-flex align-items-center">
                  <img src={icon2} className="img-fluid w-75" alt="icona" data-aos="fade-zoom-in"/>
                </div>
                <div className="col-9">
                  <p className="fw-bold fs-3 title-landing-odi" data-aos="fade-zoom-in">Oro nominale</p>
                  <p className="text-light" data-aos="fade-zoom-in">Ogni lingotto è di proprietà esclusiva del singolo cliente a cui viene attribuito un <b>codice seriale e un codice sigillo univoco che lo identifica</b>.</p>                
                </div>
              </div>
              <div className="col-xl-6 col-10 mb-xl-0 mb-5 d-flex justify-content-center align-items-top mb-3">
                <div className="col-2 d-flex align-items-center">
                  <img src={icon3} className="img-fluid w-75" alt="icona" data-aos="fade-zoom-in"/>
                </div>
                <div className="col-9">
                  <p className="fw-bold fs-3 title-landing-odi" data-aos="fade-zoom-in">Monitoraggio 24/24</p>
                  <p className="text-light" data-aos="fade-zoom-in">I Clienti Careisgold hanno a disposizione <b>un'App</b> dedicata dove poter controllare lo stato del proprio piano in Oro, i versamenti, le fatture e i verbali di controllo dei caveaux.</p>                
                </div>
              </div>
              <div className="col-xl-6 col-10 mb-xl-0 mb-5 d-flex justify-content-center align-items-top mb-3">
                <div className="col-2 d-flex align-items-center">
                  <img src={icon4} className="img-fluid w-75" alt="icona" data-aos="fade-zoom-in"/>
                </div>
                <div className="col-9">
                  <p className="fw-bold fs-3 title-landing-odi" data-aos="fade-zoom-in">Monetizzazione in sole 48 ore</p>
                  <p className="text-light" data-aos="fade-zoom-in">Careisgold offre la possibilità di <b>convertire l’oro accumulato in denaro in sole 48 ore</b>.</p>
                </div>
              </div>
            </div>
          </div>
          {/* mobile */}
          <div className="d-block d-md-none container pb-5">
            <div className="row d-flex justify-content-center align-items-top">
              <div className="col-12 text-center my-5">
                <p className="fw-semibold title-landing-odi fs-1 m-0" data-aos="fade-zoom-in">L'innovazione di Careisgold SpA</p>
                <p className="fw-semibold text-light fs-3" data-aos="fade-zoom-in">Banco metalli autorizzato da Banca d'Italia</p>
              </div>
              <div className="row d-flex justify-content-center align-items-center mb-3">
                <div className="col-3">
                  <img src={icon1} className="img-fluid" alt="icona" data-aos="fade-zoom-in"/>
                </div>
                <div className="col-9">
                  <p className="fw-bold fs-4 title-landing-odi m-0" data-aos="fade-zoom-in">Acquisto e stoccaggio dell'Oro</p>
                </div>
                <div className="col-12 mt-3">
                  <p className="text-light" data-aos="fade-zoom-in">Il cliente ha facoltà di detenere l’oro nel luogo dove preferisce oppure può scegliere di conservarlo nei sicurissimi caveaux di Battistolli in Italia beneficiando di una <b>copertura assicurativa al 100%</b>.</p>
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center mb-3">
                <div className="col-3">
                  <img src={icon2} className="img-fluid" alt="icona" data-aos="fade-zoom-in"/>
                </div>
                <div className="col-9">
                  <p className="fw-bold fs-4 title-landing-odi m-0" data-aos="fade-zoom-in">Oro nominale</p>
                </div>
                <div className="col-12 mt-3">
                  <p className="text-light" data-aos="fade-zoom-in">Ogni lingotto è di proprietà esclusiva del singolo cliente a cui viene attribuito un <b>codice seriale e un codice sigillo univoco che lo identifica</b>.</p>                
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center mb-3">
                <div className="col-3">
                  <img src={icon3} className="img-fluid" alt="icona" data-aos="fade-zoom-in"/>
                </div>
                <div className="col-9">
                  <p className="fw-bold fs-4 title-landing-odi m-0" data-aos="fade-zoom-in">Monitoraggio 24/24</p>
                </div>
                <div className="col-12 mt-3">
                  <p className="text-light" data-aos="fade-zoom-in">I Clienti Careisgold hanno a disposizione <b>un'App</b> dedicata dove poter controllare lo stato del proprio piano in Oro, i versamenti, le fatture e i verbali di controllo dei caveaux.</p>                
                </div>
              </div>
              <div className="row d-flex justify-content-center align-items-center mb-3">
                <div className="col-3">
                  <img src={icon4} className="img-fluid" alt="icona" data-aos="fade-zoom-in"/>
                </div>
                <div className="col-9">
                  <p className="fw-bold fs-4 title-landing-odi m-0" data-aos="fade-zoom-in">Monetizzazione in sole 48 ore</p>
                </div>
                <div className="col-12 mt-3">
                  <p className="text-light" data-aos="fade-zoom-in">Careisgold offre la possibilità di <b>convertire l’oro accumulato in denaro in sole 48 ore</b>.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row d-flex justify-content-center align-items-top">
                  <div className="col-xxl-8 col-xl-7 col-12 text-center">
                      <img src={imgFamily} className="img-fluid" alt="famiglia" data-aos="fade-right"/>
                  </div>
                  <div className="col-xxl-4 col-xl-5 col-12 mt-xl-0 mt-5 text-md-start text-center text-light">
                    <div data-aos="fade-left">
                    <p className="text-start fs-3"><b>Richiedi una consulenza gratuita</b></p>
                    <p className=" text-start fs-5">Potrai valutare le nostre soluzioni studiate per ogni esigenza, a partire dal costo di una colazione al giorno.</p>
                    <p className=" text-start fs-5">Careisgold SpA, in qualità di Banco Metalli autorizzato da <b>Banca d'Italia</b>, propone solo lingotti <b>24K di purezza 999,9‰</b> dotati dei massimi sistemi di anti-contraffazione e provenienti da filiere etiche e certificate "<b>Good Delivery</b>".
                    </p>
                    <p className=" text-start fs-5">Acquista in sicurezza.
                    Proteggi le persone che ami.</p>
                    <button onClick={openModal} className="border-0 bg-landing-odi text-dark mb-4 mt-3 px-4 py-2 rounded-pill fw-semibold">Prenota una consulenza</button>
                    </div>
                  </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light pt-5">
          <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-7 d-xl-block d-none text-center">
                  <img src={imgDevices} className="img-fluid" alt="famiglia"/>
                </div>
                <div className="col-12 col-xl-5 col-lg-10 text-start text-dark fw-semibold">
                    <p className="fs-3 text-uppercase" data-aos="fade-in">Portale online dedicato</p>
                    <p className="fs-5" data-aos="fade-in">Portale dedicato attivo 24 ore su 24 tramite APP da cui poter monitorare:</p>
                    <ul className="p-3 fs-5">
                      <li className="" data-aos="fade-in">
                        lo stato del piano in Oro
                      </li>
                      <li className="" data-aos="fade-in">
                        i lingotti acquistati e in fase di preparazione
                      </li>
                      <li className="" data-aos="fade-in">
                        i versamenti
                      </li>
                      <li className="" data-aos="fade-in">
                        le fatture
                      </li>
                      <li className="" data-aos="fade-in">
                        le statistiche sull'Oro
                      </li>
                      <li className="" data-aos="fade-in">
                        i verbali dei controlli dell'Oro nei caveaux
                      </li>
                    </ul>
                </div> 
                <div className="col-12 col-lg-10 d-xl-none d-block text-center">
                  <img src={imgDevices} className="img-fluid" alt="famiglia"/>
                </div>           
            </div>
          </div>
        </div>
        <div className="container-fluid bg-light mt-5">
        <div className="row d-flex justify-content-center align-items-center bg-black">
                <div className="col-md-11 col-12 text-center">
                    <p className="fw-semibold m-0 pb-0 pt-5 fs-1 position-relative text-default" data-aos="fade-zoom-in" style={{"top":"2rem"}}>Hanno scritto di noi</p>
                </div>
                <div className="col-12">
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={0}
                        centerInsufficientSlides={true}
                        breakpoints={{
                            640: {
                              slidesPerView: 2,
                              spaceBetween: -100,
                            },
                            768: {
                              slidesPerView: 3,
                              spaceBetween: 0,
                            },
                            1024: {
                              slidesPerView: 4,
                              spaceBetween: 0,
                            },
                            1200: {
                                slidesPerView: 5,
                                spaceBetween: -400,
                            },
                            1400: {
                                slidesPerView: 6,
                                spaceBetween: -400,
                            },
                          }}
                        autoplay={{
                            delay: 1000,
                            disableOnInteraction: false,
                        }} 
                        navigation={true}
                        modules={[ Autoplay , Navigation]}
                        className="mySwiper"
                        > 
                              
                        <ul className="stories">     
                        
                        {Object.values(uniqueCatPosts).map(postsWithSameCat => {
                            const categoryId = postsWithSameCat[0].IdCategory;
                            const postsWithSameCategory = posts.filter(post => post.IdCategory === categoryId);
                            const isActive = JSON.stringify(filteredPosts) === JSON.stringify(postsWithSameCategory);
                            return(
                            postsWithSameCat.length > 0 ? (
                            <React.Fragment key={postsWithSameCat[0].IdCategory}>
                                <SwiperSlide>
                                <li className={`p-0 story-item-default ${isActive ? 'story-item-active' : ''}`}>
                                    <Link to="/notizie/rassegna-stampa" onClick={handleScroll}> 
                                    <div className="image">
                                        <img src={postsWithSameCat[0].ImgLogo} alt="Logo Redazione" className="" style={{"width":"10rem", "height":"10rem"}}/>
                                    </div>
                                    </Link>
                                </li>

                            </SwiperSlide> 
                            </React.Fragment>
                            ) : null
                        )})}
                        </ul>
                    </Swiper> 
                </div>
            </div>
        </div>
        {isModalOpen && (
        <div className="modal-overlay-custom">
          <div className="container-fluid fixed-top lastModal">
            <div className="row d-flex justify-content-end align-items-top">
              <div className="col text-end">
                <button class="border-0 bg-transparent text-light p-md-4 p-0 pt-1" onclick={closeModal}>X</button>       
              </div>
            </div>
          </div>
          <div className="modal-custom" ref={modalRef}>
            <div className="modal-content-custom">
              <ContactForm 
                  NomeLanding='Landing Oro da investimento'
                  Tipo='Cliente'
                  BaseInputs={[    
                      { name: 'nomeCognome', required: true},
                      { name: 'email', required: true},
                      { name: 'telefono', required: true},
                  ]}
                  City={[{
                      city: false, 
                      required: true,
                      cityName: 'Città',
                      placeholder: 'Città'
                  }]}
                  DropDown={[{
                      dropDown: false, 
                      title:"Interessato/a a:", 
                      defaultOption: "Le nostre soluzioni", 
                      disabled: false, 
                      option1: 'Collabora con noi',
                      option2: 'altro',
                      option3: '',
                      required: false
                  }]}
                  Vote={[{
                      vote: false, 
                      name: "Quanto ti è piaciuto l'evento?", 
                      message: true, 
                      placeholder: 'Raccontaci la tua esperienza',
                      required: false
                  }]}
                  Message={[{
                      message: true, 
                      required: false,
                      messageName: 'Messaggio',
                      placeholder: 'altro...'
                  }]}
                  ConsensoDati={[{
                      consensoDati: true, 
                      required: true,
                      idPrivacy: 1
                  }]}
                  ConsensoNewsletter={[{
                      consensoNewsletter: true, 
                      listId: 14,
                      required: false,
                      idPrivacy: 3
                  }]}
                  SubmitButton={[{
                      submitName:'Invia', 
                      submittedName:'Inviato',
                      submittingName:'Sto inviando...'
                  }]}
                  SuccessMessage={[{
                      successMessage:'La tua richiesta è stata inoltrata con successo!', 
                      successButton:'Visita il nostro sito!', 
                      url:'https://www.careisgold.it/'
                  }]}
                />     
            </div>
          </div>
        </div>
      )}
      <Footer/>
      <div className="col-3 col-md-2 col-xl-1 ms-auto text-end fixed-bottom ">
        <a className="text-light nav-link" rel="noreferrer" target="_blank" href="tel:0458213155">
          <button type="button" className="btn-call p-2 m-3 bg-landing-odi border-0 rounded-circle" id="callToAction">
            <i className="fa-solid fa-phone-volume m-3 text-dark fs-1"></i>
          </button>
        </a>
      </div>
    </>
  )
}

export default Careisgold
